import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { useStyles } from "./FormLabelCheckbox.styles";

const FormLabelCheckbox = ({
  labelClassName,
  checkboxClassName,
  formControl,
  name,
  labelTitle,
  disabled,
  onBlur
}) => {
  const classes = useStyles();

  return (
    <label
      className={clsx(labelClassName, classes.checkboxLabel)}
    >
      <Controller
        control={formControl}
        name={name}
        render={({ field }) => (
          <input
            {...field}
            className={clsx(checkboxClassName, classes.checkbox)}
            type="checkbox"
            checked={field.value}
            data-checked={field.value}
            disabled={disabled}
            onBlur={onBlur}
          />
        )}
      />
      {labelTitle}
    </label>
  );
};

FormLabelCheckbox.defaultProps = {
  labelClassName: "",
  checkboxClassName: "",
  formControl: {},
  name: "",
  labelTitle: "",
  disabled: false,
  handleBlur: () => {},
};

FormLabelCheckbox.propTypes = {
  labelClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  formControl: PropTypes.any,
  name: PropTypes.string,
  labelTitle: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
};

export default FormLabelCheckbox;
