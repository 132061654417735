/* Utilities */
import moment from "moment";

/* Constants */
import { CREDENTIAL_TYPES, VALIDATION_TYPES_ENUM, TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

/* Services */
import CredentialService from "../../../services/CredentialService";
import TicketService from "../../../services/TicketService";
import ValidationAccountService from "../../../services/ValidationAccountService";
import ValetParkingService from "../../../services/ValetParkingService";

export default class CredentialValidators {
  constructor(apiClient) {
    this.apiClient = apiClient;
    this.credentialService = new CredentialService(this.apiClient);
    this.ticketService = new TicketService(this.apiClient);
    this.validationAccountService = new ValidationAccountService(
      this.apiClient
    );
    this.valetParkingService = new ValetParkingService(this.apiClient);
  }

  validateTicket = async (scannedTicket, facilityID) => {
    try {
      const response = await this.ticketService.getTicket(scannedTicket, facilityID);
      const ticket = response.data;

      let withinValidRange =
        ticket.rateValidUntil != null
          ? moment(ticket.rateValidUntil + "Z").isSameOrAfter(
            moment().toISOString()
          )
          : false;

      if (
        ticket.status === "BackoutWithTicket" ||
        ticket.status === "BackoutWithoutTicket"
      )
        return null;

      var ticketRef = ticket.ticketID.slice(-12);

      if (ticket.transactionState == 1) {
        return {
          isValid: false,
          ticketRef,
          reasonPhrase: "Ticket could not be found",
          reason: "NotFound",
        };
      } else if (ticket.status == "PayOnEntry") {
        return {
          isValid: true,
          ticket: {
            ticketID: ticket.ticketID,
            credentialID: ticket.credentialID,
            credentialType: CREDENTIAL_TYPES.TICKET,
            parkingTransactionID: ticket.parkingTransactionID,
            rateName: ticket.rateName,
            validations: ticket.validations,
            deviceID: ticket.deviceID,
          },
        };
      } else if (ticket.rateValidUntil != null && withinValidRange) {
        return {
          isValid: true,
          alreadyPaid: true,
          ticketRef,
          reasonPhrase: "This ticket is already paid - proceed to exit.",
          reason: "statusPaid",
          ticket: {
            ticketID: ticket.ticketID,
            credentialID: ticket.credentialID,
            credentialType: CREDENTIAL_TYPES.TICKET,
            parkingTransactionID: ticket.parkingTransactionID,
            rateName: ticket.rateName,
            validations: ticket.validations,
            deviceID: ticket.deviceID,
          },
        };
      } else if (ticket.status == "Exit" || ticket.status == "ManualOut") {
        return {
          isValid: false,
          ticketRef,
          reasonPhrase: "This ticket is marked as out.",
          reason: "statusOut",
        };
      } else if (ticket.status == "Void" || ticket.status == "ManualVoid") {
        return {
          isValid: false,
          ticketRef,
          reasonPhrase: "This ticket has been voided.",
          reason: "voidTicket",
        };
      } else {
        // ok, found ticket
        return {
          isValid: true,
          ticket: {
            ticketID: ticket.ticketID,
            credentialID: ticket.credentialID,
            credentialType: CREDENTIAL_TYPES.TICKET,
            parkingTransactionID: ticket.parkingTransactionID,
            rateName: ticket.rateName,
            validations: ticket.validations,
            deviceID: ticket.deviceID,
          },
        };
      }
    } catch (err) {
      return {
        isValid: false,
        reasonPhrase: err.message,
        reason: "lookupTicketError",
      };
    }
  };

  verifyValidation = async (
    validation,
    facilityID,
    discountsApplied,
    selectedOffer,
    scopeAwareFacilityID
  ) => {
    try {
      const barcodeResponse = await this.credentialService.validateCashierCredential(
        validation,
        facilityID,
        CREDENTIAL_TYPES.BARCODE,
        moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ")
      );
      if (barcodeResponse?.status !== 200 || !barcodeResponse?.data) {
        return { isValid: false, reasonPhrase: "Invalid Discount" };
      }
      const offerResponse = await this.validationAccountService.getValidationOfferByInventoryIdAttendedCashier(
        barcodeResponse.data.validationsInventoryID,
        scopeAwareFacilityID
      );

      // if valid, add to applied discounts
      if (offerResponse?.status !== 200 || offerResponse?.data === null) {
        return { isValid: false, reasonPhrase: "Invalid Discount" };
      }

      if (selectedOffer) {
        // check max uses
        let timesOnThisTrx = discountsApplied?.filter(
          (x) =>
            x.validationsInventoryID ==
            offerResponse.data.validationsInventoryID
        ).length;
        if (
          timesOnThisTrx >= offerResponse.data.maxUsesPerTrx ||
          barcodeResponse.data.currentUses >= offerResponse.data.maxUses
        ) {
          return {
            isValid: false,
            reasonPhrase: "Uses of validation exceeded",
          };
        }
      }

      // check for valid type
      if (
        selectedOffer &&
        offerResponse.data.validationTypeID !=
        VALIDATION_TYPES_ENUM["Flat Fee"] &&
        offerResponse.data.validationTypeID !=
        VALIDATION_TYPES_ENUM["Percentage"] &&
        offerResponse.data.validationTypeID != VALIDATION_TYPES_ENUM["Amount"]
      ) {
        return {
          isValid: false,
          reasonPhrase: "Discount type not valid for this transaction",
        };
      } else {
        offerResponse.data.validationOfferName =
          barcodeResponse.data.validationOfferName;
        return { isValid: true, validation: offerResponse.data };
      }
    } catch (err) {
      if (err?.response?.status == 404 && err.response?.data?.errorMessage) {
        return {
          isValid: false,
          reasonPhrase: err.response.data.errorMessage,
        };
      } else {
        return {
          isValid: false,
          reasonPhrase: "Invalid Discount",
        };
      }
    }
  };

  validateCredentialForCashier = async (scannedCredential, shiftDeviceID, shiftEntityID, attended, shiftID) => {
    try {
      let deviceIDToUse = attended ? shiftDeviceID : shiftEntityID;
      const response = await this.credentialService.ValidatePOE(
        scannedCredential,
        deviceIDToUse,
        CREDENTIAL_TYPES.BARCODE,
        moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
        shiftID,
        attended ? false : true
      );

      if (response.data != null && response.data.isValid === true) {
        if (response.data.credentialType.toLowerCase() === "prepaid" &&
          moment.utc(response.data?.activationDate) > moment.utc(Date.now())) {
          return {
            isValid: false,
            reasonPhrase: "Prepaid pass not valid yet",
            reason: "passNotValidYet",
          };
        }
        return {
          isValid: true,
          reasonPhrase: "Access Granted",
          reason: "accessGranted",
          credential: {
            credentialID: response.data.credentialID,
            credentialType: response.data.credentialType,
            accessGroupID: response.data.accessGroupID,
            reference: response.data.credentialReference,
            secondaryCredential: response.data.secondaryCredential,
            shiftDeviceID: shiftDeviceID,
            shiftEntityID: shiftEntityID,
          },
        };
      } else {
        return this.BuildErrorResult("Access Denied", "accessDenied");
      }
    } catch (err) {
      if ((err?.response?.data.credentialType ?? "").toLowerCase() === "ticketmaster") {
        return this.GetErrorMessageForTicketMaster(err?.response?.data);
      } else if ((err?.response?.data.credentialType ?? "").toLowerCase() === "prepaid") {
        return this.GetErrorMessageForPrepaid(err?.response?.data);
      } else {
        return this.BuildErrorResult("Access Denied", "accessDenied");
      }
    }
  };

  validateValetArrivalTicket = async (entityID, ticketNumber) => {
    try {
      const response = await this.valetParkingService.validateArrivalTicketNumber(entityID, ticketNumber);
      if (response?.data?.success) {
        return {
          isValid: true,
          ticket: {
            ticketNumber: ticketNumber,
            ticketType: TICKET_FIELD_REQUEST_TYPE.ARRIVAL
          }
        };
      } else if (response?.data?.message === 'Ticket Already Used') {
        return this.BuildErrorResult("Ticket Already Used", "PaidTicket");
      } else {
        return this.BuildErrorResult("Invalid Ticket", "invalidTicket");
      }
    } catch (err) {
      if (err?.response?.status == 400) {
        return this.BuildErrorResult("Invalid Ticket", "invalidTicket");
      } else {
        return this.BuildErrorResult("Unable to validate ticket number", "lookupTicketError");
      }
    }
  };

  validateValetRequestTicket = async (entityID, ticketNumber) => {
    try {
      const response = await this.valetParkingService.validateRequestTicketNumber(entityID, ticketNumber);

      if (response?.data?.success) {
        return {
          isValid: true,
          ticket: {
            ticketNumber: ticketNumber,
            ticketType: TICKET_FIELD_REQUEST_TYPE.REQUEST
          }
        };
      } else if (response?.data?.message === 'Ticket Already Requested') {
        return this.BuildErrorResult("Ticket Already Requested", "PaidTicket");
      } else {
        return this.BuildErrorResult("Ticket does not exist", "invalidTicket");
      }
    } catch (err) {
      if (err?.response?.status == 400) {
        return this.BuildErrorResult("Ticket does not exist", "invalidTicket");
      } else {
        return this.BuildErrorResult("Unable to validate ticket number", "lookupTicketError");
      }
    }
  };

  getValetTicket = async (entityID, ticketNumber) => {
    try {
      const response = await this.valetParkingService.getTicketDetails(entityID, ticketNumber);
      const ticket = response.data;
        return {
          isValid: true,
          ticket: {
            ticketID: ticket.ticketID,
            credentialID: ticket.credentialID,
            credentialType: CREDENTIAL_TYPES.VALETTICKET,
            parkingTransactionID: ticket.parkingTransactionID,
            rateName: ticket.rateName
          },
        };
    } catch (err) {
      return this.BuildErrorResult("Unable to fetch ticket details", "lookupTicketError");
    }
  };

  BuildErrorResult = (phrase, reason) => {
    return {
      isValid: false,
      reasonPhrase: phrase,
      reason: reason,
    };
  };

  GetErrorMessageForTicketMaster = (data) => {
    switch (data.resultCode) {
      case "CredentialNotFound":
        return this.BuildErrorResult("Credential Not Found", "credentialNotFound");
      case "PassNotValidYet":
        return this.BuildErrorResult("Not Valid Yet", "passNotValidYet");
      case "PassExpired":
        return this.BuildErrorResult("Expired Credential", "passExpired");
      case "CredentialNotFound":
        return this.BuildErrorResult("Invalid Ticket", "credentialNotFound");
      case "HardAntiPassBack":
        return this.BuildErrorResult("Hard Anti-Passback", "hardAntiPassBack");
      case "StartRestriction":
        return this.BuildErrorResult("Start time rule violation", "startRestriction");
      case "EndRestriction":
        return this.BuildErrorResult("End time rule violation", "endRestriction");
      default:
        return this.BuildErrorResult("Ticketmaster Error", "ticketMasterError");
    }
  };

  GetErrorMessageForPrepaid = (data) => {
    switch (data.resultCode) {
      case "HardAntiPassBack":
        return this.BuildErrorResult("Prepaid pass already IN", "passAlreadyIn");
      case "PassExpired":
        return this.BuildErrorResult("Prepaid pass expired", "passExpired");
      case "PassNotValidYet":
        return this.BuildErrorResult("Prepaid pass not valid yet", "passNotValidYet");
      default:
        return this.BuildErrorResult("Prepaid pass not valid", "passNotValid");
    }
  };
}