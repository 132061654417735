import React from "react";

/* Components */
import { Typography } from "@material-ui/core";

/* Style */
import clsx from "clsx";

/* Utilities */
import moment from "moment-timezone";

/* State */
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";
import { useSelector } from "react-redux";

const getBreakdownFromState = (
  {
    selectedOffer,
    scannedCredential,
    entryTime,
    exitTime,
    initialTotal,
    validations,
  },
  timeZone
) => {
  const selectedTicketID = selectedOffer ? "" : scannedCredential?.ticketID;
  const ticketParseResult = new RegExp("[0-9a-f]{12}$", "g").exec(
    selectedTicketID
  )?.[0] || selectedTicketID; 

  let entryTimeResult, paidDate, exitByResult;

  if (selectedOffer) {
    entryTimeResult = moment
      .tz(entryTime?.split("+")?.[0], timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
    exitByResult = moment
      .tz(exitTime?.split("+")?.[0], timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
  } else if (scannedCredential?.status === "PayOnEntry") {
    entryTimeResult = moment
      .tz(`${scannedCredential?.activityDate}+00:00`, timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
    paidDate = moment
      .tz(new Date().toISOString(), timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
  } else {
    entryTimeResult = moment
      .tz(`${entryTime}`, timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
    paidDate = moment
      .tz(new Date().toISOString(), timeZone)
      ?.format("ddd, MMM D, YYYY h:mm A");
  }

  return {
    ticketID: ticketParseResult,
    offerName: selectedOffer?.offerName,
    entryTime: entryTimeResult !== "Invalid date" ? entryTimeResult : undefined,
    paidDate: paidDate !== "Invalid date" ? paidDate : undefined,
    exitBy: exitByResult !== "Invalid date" ? exitByResult : undefined,
    parkingFee: initialTotal,
    discounts: validations?.length > 0 ? validations : [],
  };
};

const CashieredDeviceCostBreakdown = ({ classes }) => {
  const paymentInformation = useSelector((state) => {
    return {
      selectedOffer: state.shiftSession.transaction.selectedOffer,
      entryTime: state.shiftSession.transaction.rateReceipt.entryTime,
      exitTime: state.shiftSession.transaction.rateReceipt.exitTime,
      validationsScanned: state.shiftSession.transaction.validations,
      validationsApplied:
        state.shiftSession.transaction.rateReceipt?.validationsApplied ?? [],
      scannedCredential: state.shiftSession.transaction.credential,
      initialTotal: state.shiftSession.transaction.initialTotal,
    };
  });
  paymentInformation.validations = paymentInformation.validationsApplied.map(
    (validation) => {
      const discountName =
        paymentInformation.validationsScanned?.find(
          (discount) =>
            discount.id === validation.id ||
            discount.validationsInventoryID === validation.id
        )?.validationOfferName || "";
      return { ...validation, name: discountName };
    }
  );

  const { timeZone } = useCurrentFacilityTimezone();

  const breakdownValues = getBreakdownFromState(paymentInformation, timeZone);

  return (
    <div className={classes.costBreakdownWrapper}>
      {breakdownValues.ticketID && (
        <Typography
          className={clsx("breakdown-ticketID", classes.breakdownTicketID)}
          variant="caption"
          component="div"
          data-id={breakdownValues.ticketID}
        >
          TicketID: {breakdownValues.ticketID}
        </Typography>
      )}
      {breakdownValues.offerName && (
        <Typography
          className={clsx("breakdown-offerName", classes.breakdownText)}
          variant="caption"
          component="div"
        >
          <div className={clsx("label")}>Offer:</div>
          <div className={clsx("value")}>{breakdownValues.offerName}</div>
        </Typography>
      )}
      {breakdownValues.entryTime !== undefined && (
        <Typography
          className={clsx("breakdown-entryTime", classes.breakdownText)}
          variant="caption"
          component="div"
        >
          <div className={clsx("label")}>Entry Time:</div>
          <div className={clsx("value")}>{breakdownValues.entryTime}</div>
        </Typography>
      )}
      {breakdownValues.paidDate !== undefined && (
        <Typography
          className={clsx("breakdown-paidDate", classes.breakdownText)}
          variant="caption"
          component="div"
        >
          <div className={clsx("label")}>Paid Date:</div>
          <div className={clsx("value")}>{breakdownValues.paidDate}</div>
        </Typography>
      )}
      {breakdownValues.exitBy !== undefined && (
        <Typography
          className={clsx("breakdown-exitBy", classes.breakdownText)}
          variant="caption"
          component="div"
        >
          <div className={clsx("label")}>Exit By:</div>
          <div className={clsx("value")}>{breakdownValues.exitBy}</div>
        </Typography>
      )}
      {breakdownValues.parkingFee !== undefined && (
        <Typography
          className={clsx("breakdown-parkingFee", classes.breakdownText)}
          variant="caption"
          component="div"
        >
          <div className={clsx("label")}>Parking Fee:</div>
          <div
            className={clsx("value")}
          >{`$${breakdownValues.parkingFee?.toFixed(2)}`}</div>
        </Typography>
      )}
      {breakdownValues.discounts && breakdownValues.discounts.length > 0 && (
        <>
          <Typography
            className={clsx("breakdown-discounts", classes.breakdownDiscounts)}
            variant="caption"
            component="div"
          >
            Discounts
          </Typography>
          {breakdownValues.discounts.map((discount) => (
            <Typography
              key={discount.validationOfferName}
              className={clsx("discount-item", classes.breakdownText)}
              variant="caption"
              component="div"
            >
              <div className="discount-name">{discount.name}:</div>
              <div className="discount-value">
                {discount.minutesApplied
                  ? `${-1 * discount.minutesApplied}m`
                  : `-$${(-1 * discount.amountApplied)?.toFixed(2)}`}
              </div>
            </Typography>
          ))}
        </>
      )}
    </div>
  );
};

export default CashieredDeviceCostBreakdown;
